body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  background-color: rgb(245,245,249);
}

.h-medium {
  font-weight: 600;
  gap: 4px;
  font-size: 14px;
  --gap: none!important;
}

.h-small {
  font-size: 12px;
}

.height-100 {
  height: 100%;
}

a{
  text-decoration-line: none;
  -moz-text-decoration-line: none;
  color: inherit!important;
}

.flex-grow{
  flex-grow: 1;
}

.flex{
  display: flex;
}

.items-center{
  align-items: center;
}

.hidden{
  display: none;
}

.modal-form-btns{
  display: flex;
  gap: 8px;
  flex-direction: row;
}

.modal-form-btns button{
  flex-grow: 1;
}
.main-layout{
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  border-top: 1px solid #bfbfbf;
}

.main-layout .adm-tab-bar-item-title{
  color: #191919;
  text-align: center;
  font-family: PingFang SC;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-layout .adm-tab-bar-item-active .adm-tab-bar-item-title{
  color: #FF3773;
}

.admin .logo{
  color: white;
  font-size: 18px;
  text-align: start;
  font-weight: 600;
}

.admin .adm-l-h{
  padding: 0 16px!important;
  background-color: rgb(84 183 156)!important;
}

.admin .adm-l-h span {
  font-size: 18px;
}

.admin .adm-l-sider i.iconfont {
  font-size: 19px;
}

.app-loading {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.custom-calendar-cell{
  display: flex;
  align-items: baseline;
  gap: 4px;
  justify-content: end;
}

.custom-calendar-cell-reported{
  width: 10px;
  font-size: 10px;
  color: #52c41a;
}

.adm-list-item-disabled.adm-list-item-disabled > .adm-list-item-content > *,
.adm-selector-item-disabled {
  opacity: 0.8!important;
}

.adm-button.vega-btn{
  --background-color: #FF3773;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  border-radius: 9999px;
  padding: 8px 16px;
  color: #fff;
}